
import React from 'react'
// import logo from '../../assets/logo-2.png'
import logo from '../../assets/logo2.png'

import './landing.css'
// import videoBg from '../../assets/knmvideo.mp4'
import vidone from '../../assets/vidone.mp4'
import { useNavigate } from 'react-router-dom'
// import gog from '../../assets/logo-3.png'
import { useTypewriter, Cursor } from 'react-simple-typewriter'

const Landing = () => {
const navigate = useNavigate("")

    const signup = ()=>{
        navigate("/signup")
    }

    const login = ()=>{
        navigate("/login")
    }
    const [text] = useTypewriter({
        words:['VISIT GHANA,','TOUR GHANA,', 'TRUE CULTURE TO UPHOLD'],
        loop:{},
        typeSpeed:100,
        deleteSpeed:80,
    });

 
  return (
  <div className='banner'>
    <div className='slider'>
         {/* <img ref={changeimage} src={bg} alt='' id='slideimg'/> */}
         <video src={vidone} autoPlay loop muted/> 
    </div>
        <div className='overlay'>
            <div className='navbar'>
                <div className='logo'>
                    <img src={logo} alt='' />
                    <p className='kn'>Kwame Nkrumah</p>
                    <p className='mp'>Memorial Park</p>
                </div>
                <div className='menu-icons'>
                
                    <i className='fas fa-regular fa-house'></i>
                    <i className="fas fa-regular fa-user" onClick={login}></i>
                    <i className='fas fa-regular fa-image'></i>

                </div>
            </div>
            <div className='content'>
                {/* <img src={gog} alt='gog logo' /> */}
                <h4>
                    <br />
                    <span className='knchild'>
                        {text}
                    <Cursor/>
                    </span>
                    </h4>
                
                <h2>Buy ticket & explore KNM Park</h2>
                <br></br>
                <div id='btn'>
                    <button className='btnelement btn-1' type='button' onClick={signup}>Explore more? Signup </button>
                    <button type='button' className='btnelement btn-2' onClick={login}>Login to visit the Park</button>
                </div>
            </div>
        </div>
    </div>
  )
}



export default Landing
