import { yupResolver } from '@hookform/resolvers/yup'
import React, {  } from 'react'
import { useForm } from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom'
 import goglogo from '../../assets/logo-2.png'
//import AuthContext from '../../context/AuthProvider'
import './resetpwdemail.css'
import * as yup from 'yup'
import Axios from '../../api/Axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object({
  email:yup.string().required("Email field is required!").email(),
})

const Resetpwdemail = () => {
  const changepasswordurl = "/auth/user-forgotpassword"
  // const resendpinurl= "/auth/resend-pin"
    //const {setAuth} = useContext(AuthContext)
    const navigate = useNavigate();
    // const [loading, setloading] = useState(false);
    const {handleSubmit, register, formState:{errors}} = useForm({
        resolver:yupResolver(schema),
        email:''
    });
    console.log(errors);
  
  const formSubmit = async ({email})=>{
    const res = await Axios.post(changepasswordurl ,{email})
    .then(response=>{
      //console.log(response);
      const {access_token, message} = response.data
      if(access_token){
        localStorage.setItem("knmpdata", JSON.stringify(response.data))
        toast.success(message,{
          position:toast.POSITION.TOP_CENTER,
          className:'success'
        })
        setTimeout(()=>{
          navigate("/reset-pin")
        }, 3000)
      }
      if(!access_token){
        toast.error(message,{
          position:toast.POSITION.TOP_CENTER
        })
      }
    }).catch(error=>{
      toast.error(error.message,{
        position:toast.POSITION.TOP_CENTER
      })
    })
    return res;
  }
  return (
    <div className='resetpage'>
      <ToastContainer/>
    <div className='resetContainer'>  
   <div className='resetWrapper'>
       <span className="reset-logo">Kwame Nkrumah Memorial Park Portal</span>
       <img src={goglogo} alt='goglogo' />
       <span className="reset-title">Enter your Email</span>
       <form className='resetemailform' onSubmit={handleSubmit(formSubmit)}>
        <div className='resetemailbox'>
          <i className='fas fa-thin fa-envelope'></i>
          <input id='email' name='email' type="email" {...register('email', { required: true })} />
          <label htmlFor="">Email</label>
          <span className='danger'>
              {errors.email?.message}
          </span>
        </div>
        <div className='btnresetdiv'>
          <button type="submit" className='btnreset'>Send reset pin </button>
        </div>
           {/* {err && <span>Something went wrong</span>} */}

       </form>
       <p>Resend pin ? <Link onClick={formSubmit} to="/reset-pin" style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Resend</Link></p>
       <p>Back to login? <Link to="/login" style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Back</Link></p>
   </div>
   </div>
   </div>
  )
}

export default Resetpwdemail