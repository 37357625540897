import React, { useState } from 'react'
import '../adlogin/adlogin.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/Axios'
import { HashLoader } from 'react-spinners'
import { Link, useNavigate } from 'react-router-dom';

const schema = yup.object({
    username: yup.string().required("Enter your username"),
    password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
  });

const Adlogin = () => {
    const signupurl = "https://gcl-insurance-app.herokuapp.com/api/v1/user-signup"
     const [loading , setloading] = useState(false)
    const navigate = useNavigate("")

    const {register, handleSubmit,formState: { errors }} = useForm({ 
        resolver: yupResolver(schema),
        username:'',
        password:'' ,
    });
    toast.error(errors.message,{
        position:toast.POSITION.TOP_CENTER
    })

    const formSubmit = async ({username, password})=>{
        const res = await axios.post(signupurl, {username, password})
        .then(response=>{
            //console.log(response.data);
            const {data, access_token, message} = response.data
            if(access_token){

                    localStorage.setItem('knmpdata', JSON.stringify(response.data));
                    toast.success(message,{
                        position:toast.POSITION.TOP_CENTER
                    })
                    setloading()
                    setTimeout(()=>{
                        navigate("/")
                    },2000)
                }
                if(!access_token){
                    toast.error(data.data.username[0],{
                        position:toast.POSITION.TOP_CENTER
                    }) 
                }
        }).catch(error=>{
            //console.log(error);
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            }) 
        })
        return res
    }
    
  return (
    <>
     <div className='admin-container' style={{ height: "100vh" }}>
        <ToastContainer/>
            {
            loading?    
            <span>Loading...<HashLoader className='clips' color="#44045d" loading={loading} size={50}  /></span> 
            :
            <div className='admin-card'>
            <h2>Admin Login </h2>
            <div className='admin'>
                <form onSubmit={handleSubmit(formSubmit)}>
                    <div className='admincontactbox'>
                        <label htmlFor="">Email</label>
                        <input name='username' type="username" id='username' placeholder='Enter Username' {...register('username', { required: true })} />
                        <i className='fas fa-thin fa-envelope'></i>
                        <span className='errormsg'>
                            {errors.username?.message}
                        </span>    
                    </div>
                    <div className='admincontactbox'>
                    <label htmlFor="">Password</label>
                        <input name='password' type="password" id='password' placeholder='Enter Password' {...register('password', { required: true })} />
                        <i className='fas fa-thin fa-lock'></i>
                        <span className='errormsg'>
                            {errors.password?.message}
                        </span>
                    </div>
                
                    <div className='adminbtncls'>
                        <button type='submit' className='adminbtn btn-primary' >Login</button>
                    </div>
                    <br />
                    <p>You do have an account? <Link to="/">Signup</Link></p>
                </form>
            </div>
            <span className='comname'> <small>&#169;</small> KNMP App</span>
            {/* <img src={logo} alt="gog logo" /> */}
        </div>

        }
           
        </div>
    </>
   
  )
}

export default Adlogin


