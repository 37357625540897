import React from 'react'
import './paymentoption.css'
// import { useNavigate } from 'react-router-dom'
import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';      

const Paymentoption = () => {
    // const navigate = useNavigate("")
    const Paycard = ()=>{
        toast.warning("Payment with card  shortly disconnected!",{
            position:toast.POSITION.TOP_CENTER
        })
    }
    const Paymomo = ()=>{
        toast.warning("Payment with momo shortly disconected",{
            position:toast.POSITION.TOP_CENTER
        })
    }
  return (
    <>
    <ToastContainer/>
      <div className='ticketheading'>
      <i className="fas fa-tachometer-alt nav-link-icon"></i>
      <p>Payment Option</p>

    </div>
    <br />
    
<div className="raw" >
  <div className="calumn c1" onClick={Paycard}>
    <div className="cord cd1">
        <i className='fa fa-card'></i>
        <div className="hdng">Payment with Online Card</div>
        <span className='dt'>Mastercard, Visa card,etc</span>
    </div>
  </div>
  <div className="calumn c1" onClick={Paymomo}>
    <div className="cord cd2">
    <i className='fa fa-card'></i>
        <div className="hdng">Payment with MOMO account</div>
        <span className='dt'>MTN,Vodafone, AitelTigo, Glo, etc</span>
    </div>
  </div>
</div>
</>
   
  )
}

export default Paymentoption
