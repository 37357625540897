import React from 'react'
// import { useNavigate } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from '../../api/Axios';
// import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';   


const schema = yup.object({
    email:yup.string().required("Email field is required!").email(),
    password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
    fullname:yup.string().required("Full name is!"),
    gender:yup.string().required("Full name is required!"),
    phone_number:yup.string().required("Full name is required!"),
    reservation_date:yup.string().date,
  })

const Reschedulor = () => {
    const rescheduleurl="/auth/update-reservation"
    // const navigate = useNavigate("")


  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    fullname:'',
    gender:'',
    phone_number:'',
    reservation_date:'',
  }); 
  console.log(errors);

  const formSubmit=(user_id)=>{
    const res = axios.post(rescheduleurl+{user_id}).then(response=>{

    }).catch(error=>{
        console.log(error);
    })
    return res
  }
   
  return (
    <>
    <div className='ticketheading'>
      <i className="fas fa-tachometer-alt nav-link-icon"></i>
      <p>Ticket Reschedulor</p>
    </div>

    <div className="statuscontainer">
       <div className="form">
       <label>Ticket Pricing </label>
       
       <form onSubmit={handleSubmit(formSubmit)}>
    <div className="column">
    <div className="input-box">
       <label htmlFor='full-name'>Full name</label>
       <input type="text" id='fullname' placeholder="Enter your fullname " name='fullname' {...register('fullname', { required: true })}  />

     </div>
    </div>

    <div className="gender-box">
          <h3>Gender</h3>
          <div className="gender-option">
            <div className="gender">
              <input  type="radio" id="check-male" name="gender"  {...register('gender', { required: true })} value="Male"  />
              <label htmlFor="check-male">male</label>
            </div>
            <div className="gender">
              <input type="radio" id="check-female" name="gender" {...register('gender', { required: true })} value="Female" />
              <label htmlFor="check-female">Female</label>
            </div>
          </div>
        </div>
        <div className="column">
        <div className="input-box">
       <label htmlFor='phonenumber'>Phone number</label>
       <input type="number" id='' placeholder="--Select date-- " name='digital_address' {...register('digital_address', { required: true })}/>

     </div>
     <div className="input-box">
       <label htmlFor='phonenumber'>Reservation date</label>
       <input type="date" id='' placeholder="--Select date-- " name='digital_address' {...register('digital_address', { required: true })}/>
     </div>
        </div>

    <div className='stbtn'>
          <button className='statusbtn'>Reschedule Ticket</button>
     </div>

     </form>

  
     </div>
 </div>


      
    </>
  )
}

export default Reschedulor
