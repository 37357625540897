import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import countrydata from '../../Countrydata.json'
// import statedata from '../../tbl_state'
import statedata from '../../states.json'


const schema = yup.object({
    fullname: yup.string().required("Full Name field is required!"),
    gender: yup.string().required("Please choose the gender you belong to!"),
    country: yup.string().required("country field is required!"),
    city: yup.string().required("city field is required!"),
    digital_address: yup.string()

})

const Buyerinfo = ({ formdata, setformdata }) => {
  const { register, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        fullname: '',
        gender:'',
        country:'',
        digital_address:''
    });
    console.log(errors);
  return (
    <>
      <div className='column'>
      <div className="input-box">
          <label>Full Name</label>
          <input id='fullname' name='fullname' type="text" placeholder=" Enter full name" {...register('fullname', { required: true })} value={formdata.fullname} onChange={(e) => {setformdata({ ...formdata, fullname: e.target.value });}} />
          <span className='danger'>
              {errors.fullname?.message}
       </span>
        </div>

      <div className="input-box">
            <label htmlFor="country">Select Country</label>
            <div className="select-box">
              <select {...register('country', { required: true })} value={formdata.country} onChange={(e) => {setformdata({ ...formdata, country: e.target.value });}}>
                <option value="" >--Select country--</option>
                {
                countrydata.map((getcountry, index)=>(
                  <option value={getcountry.country_name} key={index}>{getcountry.country_name}</option>
                ))
                }
              </select>
              <span className='danger'>
               {errors.country?.message}
            </span>
            </div>
          </div>
      </div>

        
        <div className="gender-box">
          <h3>Gender</h3>
          <div className="gender-option">
            <div className="gender">
              <input  type="radio" id="check-male" name="gender"  {...register('gender', { required: true })} value="Male" onChange={(e) => {setformdata({ ...formdata, gender: e.target.value });}} />
              <label htmlFor="check-male">male</label>
            </div>
            <div className="gender">
              <input type="radio" id="check-female" name="gender" {...register('gender', { required: true })} value="Female" onChange={(e) => {setformdata({ ...formdata, gender: e.target.value });}} />
              <label htmlFor="check-female">Female</label>
            </div>
            {/* <div className="gender">
              <input type="radio" id="check-other" name="gender" {...register('gender', { required: true })} value="prefer not to say" onChange={(e) => {setformdata({ ...formdata, gender: e.target.value });}}  />
              <label htmlFor="check-other">prefer not to say</label>
            
            </div> */}
          </div>
        </div>
        <br />
        
          <div className="column">
            <div className="input-box">
            <label htmlFor="country">Select City</label>
            <div className="select-box">
              <select {...register('city', { required: true })} value={formdata.statedata} onChange={(e) => {setformdata({ ...formdata, city: e.target.value });}}>
                <option value="" >--Select city--</option>
                {
                statedata.map((statedata, index)=>(
                  <option value={statedata.state_name} key={index}>{statedata.state_name}</option>
                ))
                }
              </select>
              <span className='danger'>
              {errors.city?.message}
       </span>
            </div>
          </div>

          <div className="input-box">
        <label htmlFor="digi-address">Digital Address</label>
        <input type="text" id='digital_address' placeholder="Enter your Digital Address if Ghanaian " name='digital_address' {...register('digital_address', { required: true })} value={formdata.digital_address} onChange={(e) => {setformdata({ ...formdata, digital_address: e.target.value });}} />
          <span className='danger'>
              {errors.digital_address?.message}
       </span>
        </div>  
        </div>
      
    </>
  )
}

export default Buyerinfo
