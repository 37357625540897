import React, { useEffect } from 'react'
import './dashboard.css'
//  import osagyefo from '../../assets/nk3.jpeg'
import { useNavigate } from 'react-router-dom'
// import axios from '../../api/Axios'

import img1 from '../../assets/knmp1.png'
import img2 from '../../assets/knmp2.png'
import img3 from '../../assets/bg5.png'
import img4 from '../../assets/bg6.png'

import avatar from '../../assets/Nkrumah.png'



// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const data=[
	{
	 quote:'The black man is capable of managing its own affairs',
	 img:img1
	},
	{
	quote:'I am not African becuase I was born in Africa but because Africa was born in me',
	img:img2
	},
	{
	quote:'We face neither East nor West; We face forward...',
	img:img3
	},
  {
    quote:'We face neither East nor West; We face forward...',
    img:img4
  },
]

// const logininfourl = '/auth/user-login'




const Dashboard = () => {
// const dashboardurl = ""
const navigate = useNavigate("")
const buyticket = ()=>{
 navigate("/buytickets")
}

useEffect(()=>{
}, [])

  return (
    <>
    <div className='dashheading'>
    <i className='fas fa-home-alt nav-logo-icon'></i>
    <p>Dashboard</p>
    </div>
<div id="dscontainer">	


<div className="btncard-container">
	<span className="pro">Logged in Successfully</span>
	<img className="round" src={avatar} alt="user" />
	{/* <h3>Email:</h3> */}
	<h6>Welcome to Ghana, the land of our fathers</h6>
	<div className="buttons">
	<button className="btnbuyticket" onClick={buyticket}>
    Buy Ticket
 </button>
	<br /><br />
	</div>
</div>
{/* <br /><br /> */}
<div className="product-image">
<Swiper className="scontainer"
       modules={[Pagination, Autoplay]}
       spaceBetween={150}
       slidesPerView={1} 
      //  autoplay={true}
       autoplay={{delay:3000}}
       pagination={{ clickable: true }}
      >

{ data.map(({ img, quote}, index) => {
          return (
          <SwiperSlide key={index} className="dsnavigator">
          <div className="image">
            <img src={img} alt="osagyefo Nkrumah" style={{height:'30vh'}} /> 
          </div>
            {/* <small className="quote">
              {quote}
            </small> */}
        </SwiperSlide>
          )

          })
        }

</Swiper>
</div>

</div>

    </>

  )
}

export default Dashboard



