import React, { useState } from 'react'
import Appcontent from '../appcontent/Appcontent'
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import './layout.css'
import Dnavbar from '../doublenavbar/Dnavbar'

const AppLayout = () => {
    const [show, setShow] = useState(false)

  return (
    // <main className={show ? 'space-toggle' : null}>
    // <header className={`header ${show ? 'space-toggle' : null}`}>
    // <div className='header-toggle' onClick={() => setShow(!show)}>
    // <i className="fas fa-bars"></i>
    // </div> 
    //     <Header/>
    // </header>
    //     <div>
    //     <aside className={`sidebar ${show ? 'show' : null}`}>
    //     <nav className='nav'>
    //     <Sidebar/>
    //     </nav>
    //     </aside>
    //     </div>     
    //   <Appcontent/>
        

    // </main>
    <>
      <Dnavbar/>
      <section>
      <Appcontent/>
      </section>
    </>
    
  )
}

export default AppLayout