import React,{ }  from 'react'
import './resetpassword.css'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom';
import goglogo from '../../assets/logo-2.png'
// import AuthContext from '../../context/AuthProvider'
import * as yup from 'yup'
import Axios from '../../api/Axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// import { Error } from '../../utils/util';

const schema = yup.object({
    code:yup.string().required(4,"Email field is required!"),
  })
const Enterresetpin = () => {
    const enterpinurl = "/auth/user-verify-pin"

    // const {setAuth} = useContext(AuthContext)
    // const [err, setErr] = useState(false);
    const navigate = useNavigate();
    // const [loading, setloading] = useState(false)
    
    const {handleSubmit, register, formState:{errors}} = useForm({
      resolver:yupResolver(schema),
      code:'',
    });
    console.log(errors);

    const token = JSON.parse(localStorage.getItem('knmpdata'));
    // console.log(token);
    const headers = { 
     'Content-Type': 'application/json',
     'Authorization': `Bearer ${token.access_token}`
     }; // auth header with bearer token

    const formSubmit = async (code)=>{
            const res = await Axios.post(enterpinurl, code, {headers})
            .then(response=>{
                console.log(response);
                const {data} = response.data;
                if(data.message==="You can now reset your password"){
                toast.success(data.message,{
                    position:toast.POSITION.TOP_CENTER
                  })
                  setTimeout(()=>{
                    navigate("/reset-password")
                  }, 2000)
                }
                if(data.message==="Invalid token"){
                  toast.error(data.message,{
                    position:toast.POSITION.TOP_CENTER
                  })
                    //Error(data.message);
                }
            }).catch(error=>{
                toast.error(error.message,{
                    position:toast.POSITION.TOP_CENTER
                })
            })
            return res
    }
    
  return (
    <div className='resetpwdpage'>
        <ToastContainer/>
    <div className='resetpwdContainer'>  
   <div className='resetpwdWrapper'>
       <span className="resetpwd-logo">Kwame Nkrumah Memorial Park Portal</span>
       <img src={goglogo} alt='goglogo' />
       <span className="resetpwd-title">Set Pin</span>
       <form className='resetpwdform' onSubmit={handleSubmit(formSubmit)}>
        <div className='resetpwdbox'>
            <i className='fas fa-thin fa-lock'></i>
            <input name='code' id='code'  type="number" {...register('code', { required: true })}/>
            <label htmlFor=''>Enter one-time Pin</label>
            <span className='danger'>
                {errors.code?.message}
            </span>
          </div>
          <div className='resetpwdclass'>
          <button type='submit' className='resetpwdbtn'>Reset Password</button>
          </div>
           {/* {err && <span>Something went wrong</span>} */}
       </form>
       <p>Back to enter email to reset? <Link to="/sendresetpassword-email">Back</Link></p>
   </div>
   </div>
   </div>
  )
}

export default Enterresetpin
