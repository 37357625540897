import React from 'react'
 import goglogo from '../../assets/logo-2.png'
import './resetpassword.css'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Axios from '../../api/Axios'
import * as yup from 'yup'
import { ToastContainer , toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// import { Error } from '../../utils/util'


// const hasUppercase = (value) => /[A-Z]/.test(value);
const schema = yup.object({
  password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character'),
  //  password:yup.string().min(8,"Password must be at least 8 Characters").required(hasUppercase, "Password must at least have one Uppercase letter"),
  password_confirmation:yup.string().oneOf([yup.ref("password")], "Password must match").required("Confirm Password field is required ")
})

const Resetpassword = () => {
  //const {setAuth} = useContext(AuthContext)
  const signpurl = '/auth/user-reset-password'


  const navigate = useNavigate();
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    password:'',
    password_confirmation:''
  });
  console.log(errors);
 
  const token = JSON.parse(localStorage.getItem('knmpdata'));
    // console.log(token);
    const headers = { 
     'Content-Type': 'application/json',
     'Authorization': `Bearer ${token.access_token}`
     }; // auth header with bearer token

  const formSubmit = async ({ password, password_confirmation}) =>{
    const res = await Axios.post(signpurl, { password, password_confirmation}, {headers})
    .then(response=>{
        console.log(response);
        const {data} = response.data
        if(data.success===true){
            toast.success(data.message,{
              position:toast.POSITION.TOP_CENTER
            })
            setTimeout(()=>{
              navigate("/login")
            }, 2000)
        }
        if(data.success===false){
          toast.error(data.data.password[0],{
            position:toast.POSITION.TOP_CENTER
          })
          toast.error( data.data.password[1],{
            position:toast.POSITION.TOP_CENTER
          })
          //Error(data.data.data.password[0])
        }  
    })
    .catch(error=>{
      console.log(error);
      toast.error(error.message,{
        position:toast.POSITION.TOP_CENTER
      })
    })
      return res               
  }


  return (
    <div className='resetpwdpage'>
      <ToastContainer/>
    <div className='resetpwdContainer'>  
   <div className='resetpwdWrapper'>
       <span className="resetpwd-logo">Kwame Nkrumah Museum Portal</span>
       <img src={goglogo} alt='goglogo' />
       <span className="resetpwd-title">Reset Password</span>
       <form className='resetpwdform' onSubmit={handleSubmit(formSubmit)}>
        <div className='resetpwdbox'>
            <i className='fas fa-thin fa-lock'></i>
            <input id='password' name='password' type="password" {...register('password', { required: true })}/>
            <label htmlFor=''>Password</label>
            <span className='danger'>
              {errors.password?.message}
            </span>
        </div>
        <div className='resetpwdbox'>
            <i className='fas fa-thin fa-lock'></i>
            <input id='password_confirmation' name='password_confirmation'  type="password"  {...register('password_confirmation', { required: true })} />
            <label htmlFor=''>Repeat Password</label>
            <span className='danger'>
              {errors.password_confirmation?.message}
            </span>
        </div>
          
        <div className='resetpwdclass'>
          <button type='submit' className='resetpwdbtn'>Reset Password</button>
        </div>
           {/* {err && <span>Something went wrong</span>} */}
       </form>
   </div>
   </div>
   </div>
  )
}

export default Resetpassword