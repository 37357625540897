import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import knmplogo from '../../assets/logo2.png'
import '../tokenchannel/enteraccesstoken.css'
import './beapartner.css'


const Beapartner = () => {

  
    const [err, setErr] = useState(false);
    const navigate = useNavigate("");
  
    const handleSubmit = (e) =>{
      e.preventDefault();
  
      try {
        navigate("/access-token")
      } catch (err) {
        setErr(true)
      }
    }
  
    return (
      <>
    <div className='partnerhead'>
        <i className="fas fa-regular fa-laptop-code"></i>
        <p>Partner with us</p>
    </div>
      <div className='partnerpage'>
      <div className='partnerContainer'>  
     <div className='partnerWrapper'>
         <img src={knmplogo} alt='goglogo' />
         <span className="token-title">Be a Partner</span>
         <p>Enjoy free access to certain chambers within KNMP    </p>
         <p>Enjoy unlimited knowledge by exploring the e-library</p>
         <p>Enjoy pure Ghanaian cuisines during your time in Ghana.</p>

         <form className='tokenform' onSubmit={handleSubmit}>
          <div className='tokenbox'>
            <i className='fas fa-thin fa-envelope'></i>
            <input type="text"/>
            <label htmlFor="for">Enter your email</label>
          </div>
          <div className='partnerclass'>
            <button className='partnerbtn'>Get an access token</button>
          </div>
             {err && <span>Something went wrong</span>}
         </form>
     </div>
     </div>
     </div>

     </>
    
  )
}

export default Beapartner
