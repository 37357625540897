import React from 'react'
import './galleryscroll.css'

// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


import img1 from '../../../assets/knmpimg/20230608_144026.jpg'
import img2 from '../../../assets/knmpimg/20230608_144032.jpg'
import img3 from '../../../assets/knmpimg/20230608_144042.jpg'
import img4 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.44 PM (1).jpeg'
import img5 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.47 PM (1).jpeg'
import img6 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.43 PM.jpeg'
import img7 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.42 PM.jpeg'
import img8 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.45 PM.jpeg'
import img9 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.45 PM (1).jpeg'
import img10 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.44 PM (2).jpeg'
import img11 from '../../../assets/knmpimg/20230608_145045.jpg'
import img12 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.47 PM.jpeg'
import img13 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.43 PM (1).jpeg'
import img14 from '../../../assets/knmpimg/20230608_145501.jpg'
import img15 from '../../../assets/knmpimg/20230608_144632.jpg'
import img16 from '../../../assets/knmpimg/20230608_145614.jpg'
import img17 from '../../../assets/knmpimg/20230608_145718.jpg'
import img18 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.50 PM (2).jpeg'
import img19 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.47 PM.jpeg'
import img20 from '../../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.52 PM.jpeg'




const data=[
	{img:img1},
	{img:img2},
    {img:img3},
    {img:img4},
   {img:img5},
   {img:img6},
    {img:img7},
    {img:img8},
    {img:img9},
    {img:img10},
    {img:img11},
    {img:img12},
    {img:img13},
    {img:img14},
    {img:img15},
    {img:img16},
    {img:img17},
    {img:img18},
    {img:img19},
    {img:img20},
]


const Galleryscroll = () => {
  return (
    <div>
   
<div className="gallery-image">
<Swiper className="gcontainer"
       modules={[Pagination, Autoplay]}
       spaceBetween={150}
       slidesPerView={1} 
      //  autoplay={true}
       autoplay={{delay:4000}}
       pagination={{ clickable: true }}
      >

{ data.map(({ img, quote}, index) => {
          return (
          <SwiperSlide key={index} className="dsnavigator">
          <div className="image" style={{height:"100vh"}}>
            <img src={img} alt="osagyefo Nkrumah" /> 
          </div>
            {/* <small className="quote">
              {quote}
            </small> */}
        </SwiperSlide>
          )

          })
        }

</Swiper>
</div>
    </div>
  )
}

export default Galleryscroll
