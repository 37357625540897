import React from 'react'
import { useNavigate } from 'react-router-dom'
import './elibrary.css'

const Elibrary = () => {

    const navigate = useNavigate("")
    const loadbooks = ()=>{
        navigate("")
    }

  return (
    <>
    <div className='libraryhead'>
        <i className="fas fa-regular fa-book"></i>
        <p>KNM E-Library</p>
    </div>
    <div className='jalabia'>
    <form class='loginform' onSubmit={loadbooks}>
    <div className='loginbox'>
        <i className='fas fa-thin fa-book'></i>
      <input type="search" />
      <label htmlFor='for'>Search for books</label>
      </div>
      
      <div className='btnlog'>
        <button className='btnlogin' onClick={loadbooks}>Load all books</button>
        </div>
    </form>
    </div>
    </>
  )
}

export default Elibrary
