import React, { useRef } from 'react'
import './dnavbar.css'
// import Appcontent from '../appcontent/Appcontent'
import { Link, useNavigate } from 'react-router-dom'

import logo from '../../assets/logo2.png'



// let menu = document.querySelector('#menu-icon');
// let navbar = document.querySelector('#uldiv');



const Dnavbar = () => {
 
    // const handleClick = ()=>{
    //     menu.classList.toggle('fa-xmark');
    //      navbar.classList.toggle('open');
    // }
    
    const navigate = useNavigate("/buytickets")

   const  butticket = ()=>{
    navigate("/")
   }


   const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};



  return (
    <>
<div className='toptopbar'>
     <div className='left flex'>
        <div className='email'>
            <i className='fa fa-envelope'></i>
            <span className='s'>info@knmp.gov.gh</span>
        </div>
        <div className='call'>
            <i className='fa fa-phone-alt'></i>
            <span className='s'>+233 53 007 6746</span>
        </div>

      </div>
        <div className='right flex'>
      <div className='twitter'>
            <i className='fab fa-twitter'></i>
            {/* <span className='s'>twitter</span> */}
        </div>
        <div className='facebook'>
            <i className='fab fa-facebook'></i>
            {/* <span className='s'>facebook</span> */}
        </div>

        <div className='facebook'>
            <i className='fab fa-instagram'></i>
            {/* <span className='s'>facebook</span> */}
        </div>
     </div>
        </div>

    <header>
        <div className='logo'>
            <img src={logo} alt='logo meta'/>
            <h3>KNMP
              <br/>
               Eticketing
               </h3>
        </div>
			<nav ref={navRef}>
            <Link className='active' to='/dashboard'>Home</Link>
            <Link to='/buytickets'>Ticketing</Link>
            <Link to='/transactions'>Transactions</Link>
            <Link to='/ticket-status'>Status</Link>
            <Link to='/knm-gallery'>Gallery</Link>
            <Link to='/e-library'>Library</Link>
            <Link to='/be-a-partner'>Partner</Link>
            <Link to='/buytickets'>Helpdesk</Link>
            <Link to='/'>Logout</Link>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
            <i style={{color:"#000"}} className='fa fa-xmark'></i>
				</button>
			</nav>
			<button className="nav-btn" onClick={showNavbar}>
                <i style={{color:"#000"}} className="fa fa-bars"></i>
			</button>
		</header>

   
    </>
  )
}

export default Dnavbar
