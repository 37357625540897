import React, { useEffect, useState } from 'react'
import './transactions.css'
// import { useNavigate } from 'react-router-dom'
import axios from '../../api/Axios'
import { BeatLoader } from 'react-spinners'


const Transactions = () => {
  const transactionsurl = "/auth/show-receipt"
  // const navigate = useNavigate("")
  // const [columns, setcolumns] = useState([])
  // const [records, setrecords] = useState([])
  const [loading, setloading] = useState(false)


  const token = JSON.parse(localStorage.getItem('knmpdata'));
  const headers = { 
   'Content-Type': 'application/json',
   'Authorization': `Bearer ${token.access_token}`
   }; // auth header with bearer token
  // console.log( headers);


 
  useEffect(()=>{
    // setloading(true)
    setTimeout(()=>{
      transactionInfo();
      setloading(false)
    }, 3000)
  })

  const transactionInfo =()=>{
     axios.get(transactionsurl, {headers}).then(response=>{
      console.log(response);
      // setcolumns(Object.keys(response.data.data.message[0]))
      // setrecords(response.data.data.message)  
      }).catch(error=>{
     console.log(error);
    })
   }

  return (
    <>
<div className='transacthead'>
        <i className="fas fa-regular fa-book"></i>
        <p>Ticket Transactions</p>
   </div>

   {
     loading?
      
     <span>Loading...<BeatLoader className='clips' color="#120b4b" loading={loading} size={20}  /></span>
     :
   
   <section className='section'>
	<h1>Latest Transactions</h1>
	<details>
		<summary>
			<div>
     
        <span className='s' style={{backgroundColor: "#BDC3C7"}}>
            <i style={{color:"#21618C"}} className='fa fa-ticket'></i>
				</span>
				<h3>
					<strong>Mandela Vudugah</strong>
					<small>ticket No</small>
				</h3>
				<p className='sp'>-90.99 GHS</p>
         
			</div>
		</summary>
		<div>
			<dl>
				<div>
					<dt>Time</dt>
					<dd>4.27pm</dd>
				</div>

				<div>
					<dt>Card used</dt>
					<dd>•••• 6890</dd>
				</div>

				<div>
					<dt>Reference ID</dt>
					<dd>3125-568911</dd>
				</div>
			</dl>
		</div>
	</details>
	</section>
  }

</>
  )
}

export default Transactions
