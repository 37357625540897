import React from 'react'
import './buytickets.css'



import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'

const schema = yup.object({
    adult_visitor_category: yup.string().required("Please select elect date required"),
    numberOfAdult: yup.string().required("Please select number of ticket you want buy!"),
})

const Ticketadultinfo = ({ formdata, setformdata }) => {
        const { register, formState: { errors } } = useForm({
            resolver: yupResolver(schema),
            adult_visitor_category:'',
            number_of_adult:''
    
        });
        console.log(errors);
    
  return (
    <>
        <div className="column">
          <div className="input-box">
          <label>Adult visitor category</label>
          <div className="select-box">
            <select placeholder="Enter adult visitor category" {...register('adult_visitor_category', { required: true })} value={formdata.adult_visitor_category} onChange={(e) => {setformdata({ ...formdata, adult_visitor_category: e.target.value });}}>
              <option hidden>--Select adult visitor category--</option>
              <option value="Ghanaian Adults">Ghanaian Adults </option>
              <option value="Non-Ghanaian Adults">Non-Ghanaian Adults</option>
            </select>
          </div>
          </div>
      
          <div className="input-box">
            <label>No. of adult</label>
            <input type="number" placeholder="Enter number of adult" {...register('number_of_adult', { required: true })} value={formdata.number_of_adult} onChange={(e) => {setformdata({ ...formdata, number_of_adult: e.target.value });}}  required />
          </div>
        </div>
    </>
  )
}

export default Ticketadultinfo









