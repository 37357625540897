import React, { useState } from 'react'
import '../adlogin/adlogin.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/Axios'
import { HashLoader } from 'react-spinners'
import {  useNavigate } from 'react-router-dom';

const schema = yup.object({
    password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
    password_confirmation: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match').min(8, "Password must be at least 8 characters"),
  });

const Adresetpassword = () => {
    const signupurl = "/admin/admin-reset-password"
     const [loading , setloading] = useState(false)
    const navigate = useNavigate("")

    const {register, handleSubmit,formState: { errors }} = useForm({ 
        resolver: yupResolver(schema),
        password:'' ,
        password_confirmation:''
    });
    toast.error(errors.message,{
        position:toast.POSITION.TOP_CENTER
    })

    const formSubmit = async ({ password, password_confirmation})=>{
        const res = await axios.post(signupurl, { password, password_confirmation})
        .then(response=>{
            //console.log(response.data);
            const {data, access_token, message} = response.data
            if(access_token){

                    localStorage.setItem('ggldata', JSON.stringify(response.data));
                    toast.success(message,{
                        position:toast.POSITION.TOP_CENTER
                    })
                    setloading()
                    setTimeout(()=>{
                        navigate("/")
                    },2000)
                }
                if(!access_token){
                    toast.error(data.data.email[0],{
                        position:toast.POSITION.TOP_CENTER
                    }) 
                }
        }).catch(error=>{
            //console.log(error);
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            }) 
        })
        return res
    }
    
  return (
    <div className='admin-container' style={{ height: "100vh" }}>
        <ToastContainer/>
            {
            loading?    
            <span>Loading...<HashLoader className='clips' color="#44045d" loading={loading} size={50}  /></span> 
            :
            <div className='admin-card'>
            <h2> Manager Register </h2>
            <div className='admin'>
                <form onSubmit={handleSubmit(formSubmit)}>  
                    <div className='admincontactbox'>
                    <label htmlFor="">Password</label>
                        <input name='password' type="password" id='password' placeholder='Enter Password' {...register('password', { required: true })} />
                        <i className='fas fa-thin fa-lock'></i>
                        <span className='errormsg'>
                            {errors.password?.message}
                        </span>
                    </div>
                    <div className='admincontactbox'>
                        <label htmlFor="">Confirm Password</label>
                        <input name='password_confirmation' type="password" id='password_confirmation' placeholder='Confirm Password' {...register('password_confirmation', { required: true })} />
                        <i className='fas fa-thin fa-lock'></i>
                        <span className='errormsg'>
                            {errors.password_confirmation?.message}
                        </span>
                    </div>

                    <div className='adminbtncls'>
                        <button type='submit' className='adminbtn btn-primary' >Reset Password</button>
                    </div>
                    <br />
                </form>
            </div>
            <span className='comname'> <small>&#169;</small> Insurance App</span>
            {/* <img src={logo} alt="gog logo" /> */}
        </div>

        }
           
        </div>
  )
}

export default Adresetpassword


