import React, { useEffect, useState } from 'react'
import './ticketstatus.css'
import axios from '../../api/Axios';
import { useNavigate } from 'react-router-dom';

const Ticketstatus = () => {
  const ticketinfourl = "/auth/show-receipt"
  const ticketpriceurl = "/admin/admin-show-price"
  const navigate = useNavigate("")
  const token = JSON.parse(localStorage.getItem('knmpdata'));
  const headers = { 
   'Content-Type': 'application/json',
   'Authorization': `Bearer ${token.access_token}`
   }; // auth header with bearer token
  // console.log( headers);

  const[ticketinfo, setticketinfo] = useState([])
  const[ghchildpricing, setghchildpricing] = useState([])
  const[ghadultdpricing, setghadultpricing] = useState([])
  const[nonghchildpricing, setnonghchildpricing] = useState([])
  const[nonghadultdpricing, setnonghadultdpricing] = useState([])



 useEffect(()=>{
  const tickinfo = async ()=>{
    const res = await axios.get(ticketinfourl, {headers}).then(response=>{
     setticketinfo(response.data.data.message[0])
     //console.log(response.data);
   }).catch(error=>{
    console.log(error);
   })
   return res
  }
  tickinfo();
     priceinfo();
 })



const priceinfo = async ()=>{
  const res = await axios.post(ticketpriceurl).then(response=>{
    setghadultpricing(response.data.data.message[0])
   setghchildpricing(response.data.data.message[1])
   setnonghchildpricing(response.data.data.message[2])
   setnonghadultdpricing(response.data.data.message[3])

   //console.log(response.data);
 }).catch(error=>{
  console.log(error);
 })
 return res
}

const Pay = ()=>{
  navigate("/payment-option")
}

  return (
    <>
    <div className='tstatusheading'>
      <i className="fas fa-tachometer-alt nav-link-icon"></i>
      <p>Ticketing Information</p>
    </div>

    <div className="statuscontainer">
       <div className="form">
       <label>Ticket Pricing </label>
    <div className="column">
    <div className="input-box">
       <span>Ghanaian Child per ticket GHS </span>
       <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{ghchildpricing.enterance_fee}</label>
     </div>
     <div className="input-box">
       <span>Ghanaian Adult per ticket GHS </span>
       <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{ghadultdpricing.enterance_fee}</label>
     </div>
    </div>
    <div className="column">
    <div className="input-box">
       <span>Non-Ghanaian Child per ticket GHS </span>
       <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{nonghchildpricing.enterance_fee}</label>
     </div>
     <div className="input-box">
       <span>Non-Ghanaian Adult per ticket GHS </span>
       <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{nonghadultdpricing.enterance_fee}</label>
     </div>
    </div>
 
     </div>
 </div>



   <div className="statuscontainer">
  
     <div className='statusheader'> {ticketinfo.fullname}  </div>
        <div className="form">
          <label>Ticket(s) Information </label>
          {/* <input type="submit" placeholder=" Ticket Status"  readOnly /> */}
        
       
       <div className="column">
       <div className="input-box">
          <span>Ticket #. </span>
          <label>{ticketinfo.ticketid}</label>
        </div>
        <div className="input-box">
          <span>Phone #. </span>
            <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{ticketinfo.phone_number}</label>
          </div>
          <div className="input-box">
          <span>Email </span>
            <label >{ticketinfo.email}</label>
          </div>
       </div>

        
        <div className="column">
        <div className="input-box">
          <span>Name on ticket </span>
            <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{ticketinfo.fullname}</label>
          </div>
          <div className="input-box">
          <span>#. of Ticket bought </span>
            <label style={{color:"#4499", fontSize:"20px", fontWeight:"bold"}}>{ticketinfo.number_of_ticket}</label>
          </div>
          <div className="input-box">
          <span>Total amount </span>
            <label style={{color:"#295b70", fontSize:"20px", fontWeight:"bold"}}>GHS {ticketinfo.total_amount}.00</label>
          </div>
        </div>
        <div className="column">
        <div className="input-box">
          <span>Children ticket bought </span>
            <label style={{color:"#295b70", fontSize:"20px", fontWeight:"bold"}}> {ticketinfo.number_of_children}</label>
          </div>
          <div className="input-box">
          <span>Adult ticket bought </span>
            <label style={{color:"#295b70", fontSize:"20px", fontWeight:"bold"}}> {ticketinfo.number_of_adult}</label>
          </div>
          <div className="input-box">
          <span>Reservation date </span>
            <label style={{color:"#295b70", fontSize:"20px", fontWeight:"bold"}}> {ticketinfo.reservation_date}</label>
          </div>
        </div>
          <div className='stbtn'>
          <button className='statusbtn' onClick={Pay}>Proceed to payment</button>
          </div>
        </div>
    </div>
    
    
    </>
  )
}

export default Ticketstatus