import React, { useState } from 'react'
import { Link} from 'react-router-dom'
import videoBg from '../../assets/vidtwo.mp4'
 import goglogo from '../../assets/logo-2.png'
import './signup.css'
import Input from './Input'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
//import AuthContext from '../../context/AuthProvider';
import axios from '../../api/Axios'
import { ToastContainer, toast } from 'react-toastify'
// import { Error } from '../../utils/util'


const schema = yup.object({
  email:yup.string().required("Email field is required!").email(),
  // password:yup.string().min(8,"Password must be at least 8 Characters").required(),
  password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
  password_confirmation:yup.string().oneOf([yup.ref("password")], "Password must match").required("Confirm Password field is required ")
})

const Signup = () => {
  //const {setAuth} = useContext(AuthContext)
  const signpurl = '/auth/user-signup'


  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [showconfirm, setshowconfirm] = useState(false)
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    email:'',
    password:'',
    password_confirmation:''
  });
 

  const formSubmit = async ({email, password, password_confirmation}) =>{
    //e.preventDefault(); 
    const res = await axios.post(signpurl, {email, password, password_confirmation})
    .then(response=>{
      const { access_token, data, message} = response.data;
      if(access_token) {
        localStorage.setItem('knmpdata', JSON.stringify(response.data));
        toast.success(message,{
          position:toast.POSITION.TOP_CENTER
        })
        setTimeout(()=>{
          navigate("/otp-verification")
        }, 2000)
      }
      if(data) {
        //Error(data);
        toast.error(data.message + "Please check and signup again",{
          position:toast.POSITION.TOP_CENTER
        });
        // toast.error(data.data.password[0],{
        //   position:toast.POSITION.TOP_CENTER
        // });
        // toast.error(data.data.password[1],{
        //   position:toast.POSITION.TOP_CENTER
        // });
        toast.error(data.data.email[0],{
          position:toast.POSITION.TOP_CENTER
        });
        
        return;
      }
    })
    .catch(error=>{
      toast.error(error,{
        position:toast.POSITION.TOP_RIGHT

      })
      // console.log(error);
    })
    
      return res               
  }

  const handleLock = ()=>{
      setshow(!show);
  }

  const handleLockconfirm = ()=>{
    setshowconfirm(!showconfirm);
}

  return (
    <div className='signuppage'>
     <ToastContainer/>
    <div className='signContainer'>  
    <div className="overlay"></div>
   <video src={videoBg} autoPlay loop muted/>  
   <div className='formWrapper'>
       <span className="text-logo">Kwame Nkrumah Memorial Park Portal</span>
       <img src={goglogo} alt='goglogo'/>
       <span className="text-title">Register</span>
      <form onSubmit={handleSubmit(formSubmit)} className='signform'>
          <Input name='email' id='email' label='Email' type='email' i='fas fa-thin fa-envelope'
           register={{...register('email')}} errormessage={errors.email?.message}  />
{/* 
          <Input name='password' id='password' label='Password' type='password' i='fas fa-thin fa-lock'
           register={{...register('password')}} errormessage={errors.password?.message} /> */}

      {/* <Input name='confirmpassword' id='confirmpassword' label='Confirm Password' type='password' i='fas fa-thin fa-lock'
           register={{...register('password_confirmation')}} errormessage={errors.password_confirmation?.message} />
             */}
            <div className='signupbox'>
            <i className={show?"fas fa-thin fa-eye-slash":"fa fa-thin fa-eye fa-shake"} onClick={handleLock}></i>
            <input id='password' name='password' type={show?"text":"password"}  {...register('password', { required: true })}/>
            <label htmlFor='password'>{show?"Hide Password":"Show Password"}</label>
            <span className='danger'>
              {errors.password?.message}
            </span>
        </div>

          <div className='signupbox'>
            <i className={showconfirm?"fas fa-thin fa-eye-slash":"fa fa-thin fa-eye fa-shake"} onClick={handleLockconfirm}></i>
            <input id='confirmpassword' name='confirmpassword' type={showconfirm?"text":"password"} {...register('password_confirmation', { required: true })}/>
            <label htmlFor='confirm password'>{showconfirm?"Hide Confirm Password":"Show Confirm Password"}</label>
            <span className='danger'>
              {errors.password_confirmation?.message}
            </span>
        </div>


           <div className='btnsign'>
           <button className='btnsignup' type='submit'>Sign up</button>
           </div>
           {/* {err && <span>Something went wrong</span>} */}
       </form>
       <p>You do have an account? <Link to="/login" style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Login</Link></p>
   </div>
   </div>
   
   </div>
  )
}

export default Signup