import React from 'react'
import './buytickets.css'

// import { yupResolver } from '@hookform/resolvers/yup';
// import { useForm } from 'react-hook-form';
// import * as yup from 'yup'



const Ticketinfo = ({ formdata, setformdata }) => {
 
  return (
    <>
      <div className="column">
          <div className="input-box">
            <label>Phone number</label>
            <input type="number" placeholder="Enter Phone number"  value={formdata.phone_number} onChange={(e) => {setformdata({ ...formdata, phone_number: e.target.value });}} />
          </div>

        <div className="input-box">
          <label>Select reservation date</label>
          <input type="date" placeholder=" select reservation date" value={formdata.reservation_date} onChange={(e) => {setformdata({ ...formdata, reservation_date: e.target.value });}}/> 
        </div>
          </div>

        <div className="column">
          <div className="input-box">
          <label>Child visitor category</label>
          <div className="select-box">
            <select placeholder="Enter child visitor category"  value={formdata.children_visitor_category} onChange={(e) => {setformdata({ ...formdata, children_visitor_category: e.target.value });}}>
              <option hidden>--Select child visitor category--</option>
              <option value="Ghanaian Children">Ghanaian Children </option>
              <option value="Non-Ghanaian Children">Non-Ghanaian Children</option>
            </select>
          </div>
          </div>
      
          <div className="input-box">
            <label>No. of children</label>
            <input type="number" placeholder="Enter number of children"  value={formdata.number_of_children} onChange={(e) => {setformdata({ ...formdata, number_of_children: e.target.value });}}  required />
          </div>
        </div>
    </>
  )
}

export default Ticketinfo
