import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Beapartner from '../../views/beapartner/Beapartner'
import Buytickets from '../../views/buyticket/Buytickets'
import Dashboard from '../../views/dashboard/Dashboard'
import Elibrary from '../../views/elibrary/Elibrary'
// import Foodmenu from '../../views/foodmenu/Foodmenu'
import Knmgallery from '../../views/knmgallery/Knmgallery'
import EnteraccessToken from '../../views/tokenchannel/Enteraccesstoken'
import Transactions from '../../views/transactions/Transactions'
import Profile from '../../views/updateprofile/Profile'
import Ticketstatus from '../../views/buyticket/Ticketstatus'
import Paymentcheckout from '../../views/buyticket/Paymentcheckout'
import Paymentoption from '../../views/buyticket/Paymentoption'
import Reschedulor from '../../views/buyticket/Reschedulor'



const Appcontent = () => {
  return (
    <Routes>
        <Route path='dashboard' element={<Dashboard/>} />
        <Route path='buytickets' element={<Buytickets/>} />
        <Route path="profile" element={<Profile/>} />
        {/* <Route path='food-menu' element={<Foodmenu/>} /> */}
        <Route path='access-token' element={<EnteraccessToken/>}/>
        <Route path='be-a-partner' element={<Beapartner/>}/>
        <Route path='e-library' element={<Elibrary/>}/>
        <Route path='knm-gallery' element={<Knmgallery/>}/>
        <Route path='transactions' element={<Transactions/>}/>
        <Route path='ticket-status' element={<Ticketstatus/>}/>   
       <Route path='payment-option' element={<Paymentoption/>}/>
        <Route path='payment-checkout' element={<Paymentcheckout/>}/>
        <Route path='reschedule-ticket' element={<Reschedulor/>}/>
        
    </Routes>
      
   
  )
}

export default Appcontent
