import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'

const schema = yup.object({
    ticketnumber: yup.string().required("Full Name field is required!"),
  

})

const Paymentcheckout = ({ formdata, setformdata }) => {
    const {  formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        fullname: ''
    });
    console.log(errors);
  return (
    <>
  <br></br>
      <h2>Payment checkout</h2>
      <div className='heading'>
    <i className='fas fa-money nav-logo-icon'></i>
    </div>
    
    {/* <div className='profile'> */}
    <div className="pfcontainer">
      {/* <div className='pfheader'>Profile Settings</div> */}
      <form className="form">
        <div className="input-box">
          <label>Email Address</label>
          <input type="text" placeholder=" Email address" readOnly />
        </div>
        <div className="input-box">
          <label htmlFor='full-name'>Full Name</label>
          <input type="text" placeholder="Full Name" readOnly />
        </div>
       

        <div className="column">
          <div className="input-box">
            <label htmlFor='phone-number'>Phone Number</label>
            <input type="number" placeholder="Enter phone number" required />
          </div>
          <div className="input-box">
            <label htmlFor='upload-image'>Upload a passport size photo</label>
            <input type="file" placeholder="Upload a passport size photo" required />
          </div>
        </div>
      
        <div className='tokenclass'>
          <button className='tokenbtn'>Update Profile</button>
        </div>  
        </form>
    </div>
    {/* </div> */}

    </>
  )
}

export default Paymentcheckout
