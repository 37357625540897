import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'



function Sidebar() {
    
  return (
 
      <>
      <div>
      <Link to="/dashboard" className='nav-logo'>
        <i className='fas fa-home-alt nav-logo-icon'></i>
        {/* <img src={goglogo} alt="gog logo" /> */}
        <span className='nav-logo-name'>KNM Portal</span>
      </Link>

      <div className='nav-list'>
        <Link to="/dashboard" className='nav-link active'>
          <i className="fas fa-home-alt nav-link-icon"></i>
          <span className='nav-link-name'>Dashboard</span>
          <span className='nav-tooltip'>Dashboard</span>

        </Link>
        <Link to="/buytickets" className='nav-link'>
          <i className="fas fa-tachometer-alt nav-link-icon"></i>
          <span className='nav-link-name'>Buy tickets</span>
          <span className='nav-tooltip'>Buy tickets</span>
        </Link>
        {/* <Link to="/food-menu" className='nav-link'>
        <i className="fas fa-light fa-bowl-food"></i>
          <span className='nav-link-name'>Order food</span>
          <span className='nav-tooltip'>Order food</span>
        </Link> */}
        <Link to="/knm-gallery" className='nav-link'>
          <i className="fas fa-image nav-link-icon"></i>
          <span className='nav-link-name'>Gallery</span>
          <span className='nav-tooltip'>Gallery</span>
        </Link>
        <Link to="/transactions" className='nav-link'>
          <i className="fas fa-money-bill-wave nav-link-icon"></i>
          <span className='nav-link-name'>Transactions</span>
          <span className='nav-tooltip'>Transactions</span>
        </Link>

      </div>

    </div>
    <Link to="/be-a-partner" className='nav-link'>
        <i className="fas fa-regular fa-laptop-code"></i>
        <span className='nav-link-name'>Be a Partner</span>
        <span className='nav-tooltip'>Be a Partner</span>
    </Link>
      <Link to="/" className='nav-link'>
        <i className="fas fa-sign-out nav-link-icon"></i>
        <span className='nav-link-name'>logout</span>
        <span className='nav-tooltip'>logout</span>
      </Link>
      </>
              
            
        
      
    
  )
}

export default Sidebar
