import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'
 import goglogo from '../../assets/logo-2.png'
import './enteraccesstoken.css'



const EnteraccessToken = () => {


  const [err, setErr] = useState(false);
  const navigate = useNavigate("");

  const handleSubmit = (e) =>{
    e.preventDefault();

    try {
      navigate("/e-library")
    } catch (err) {
      setErr(true)
    }
  }

  return (
    <>
     <div className='knmghead'>
    <i className="fas fa-book nav-link-icon"></i>
    <p>library acces token</p>
    </div>

    
    <div className='tokenpage'>
    <div className='tokenContainer'>  
   <div className='tokenWrapper'>
       <img src={goglogo} alt='goglogo' />
       <span className="token-title">Enter your Access Token</span>
       <form className='tokenform' onSubmit={handleSubmit}>
        <div className='tokenbox'>
          <i className='fas fa-thin fa-lock'></i>
          <input type="text"/>
          <label htmlFor="for">Enter your Access Token</label>
        </div>
        <div className='accesstokenclass'>
          <button className='accesstokenbtn'>Access E-library</button>
        </div>
           {err && <span>Something went wrong</span>}
       </form>
   </div>
   </div>
   </div>
   </>
  )
}

export default EnteraccessToken