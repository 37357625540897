import React from "react";
// import Sidebar from "./components/sidebar/Sidebar";
import Login from "./views/login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./views/signup/Signup";
import AppLayout from "./components/layout/AppLayout";
import Landing from "./views/landing/Landing";
import Otpcheck from "./views/otpcheck/Otpcheck";
import Resetpassword from "./views/resetpassword/Resetpassword";
import Resetpwdemail from "./views/resetpwdemail/Resetpwdemail";
import Enterresetpin from "./views/resetpassword/Enterresetpin";
import Adlogin from "./admin/adlogin/Adlogin";
import Adsignup from "./admin/adsignup/Adsignup";
import Adresetpass from "./admin/adsignup/Adresetpass";
import Verifypin from "./admin/adlogin/Verifypin";
import Adresetpassword from "./admin/adlogin/Adresetpassword";
import Dnavbar from "./components/doublenavbar/Dnavbar";
import Galleryscroll from "./views/landing/galleryscroll/Galleryscroll";




function App() {
  return (
    <Router>
        <Routes>
            <Route path='login' element={<Login/>} />
            

            <Route path='signup' element={<Signup/>} />
            <Route path='' exact element={<Landing/>} />
            <Route path='gallery' exact element={<Galleryscroll/>} />

            <Route path="otp-verification" element={<Otpcheck/>} />
            <Route path="sendresetpassword-email" element={<Resetpwdemail/>} />
            <Route path="reset-pin" element={<Enterresetpin/>} />
            <Route path="reset-password" element={<Resetpassword/>} />
            <Route path="*" name="Home" element={<AppLayout />} />
            <Route path="navbar" element={<Dnavbar/>} />
              {/* this admin user channels */}
              <Route path='admin-knmp-adlogin' element={<Adlogin/>} />
            <Route path='admin-knmp-adsignup' element={<Adsignup/>} />
            <Route path='delax-resetpinenteremail' element={<Adresetpass/>} />
            <Route path='delax-verifypin' element={<Verifypin/>} />
            <Route path='delax-resetpassword' element={<Adresetpassword/>} />



        </Routes>
    </Router>
  );
}

export default App;
