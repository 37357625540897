import React from 'react'
import './profile.css'

const Profile = () => {
  return (
    <>
      <br></br>
      <h2>Profile</h2>
      <div className='heading'>
    <i className='fas fa-user nav-logo-icon'></i>
    </div>
    
    <div className='profile'>
    <div className="pfcontainer">
      <div className='pfheader'>Profile Settings</div>
      <form className="form">
        <div className="input-box">
          <label>Email Address</label>
          <input type="text" placeholder=" Email address" readOnly />
        </div>
        <div className="input-box">
          <label htmlFor='full-name'>Full Name</label>
          <input type="text" placeholder="Full Name" readOnly />
        </div>
       

        <div className="column">
          <div className="input-box">
            <label htmlFor='phone-number'>Phone Number</label>
            <input type="number" placeholder="Enter phone number" required />
          </div>
          <div className="input-box">
            <label htmlFor='upload-image'>Upload a passport size photo</label>
            <input type="file" placeholder="Upload a passport size photo" required />
          </div>
        </div>
        <div className="gender-box">
          <h3>Gender</h3>
          <div className="gender-option">
            <div className="gender">
              <input type="radio" id="check-male" name="gender" checked />
              <label htmlFor="check-male">male</label>
            </div>
            <div className="gender">
              <input type="radio" id="check-female" name="gender" />
              <label htmlFor="check-female">Female</label>
            </div>
            <div className="gender">
              <input type="radio" id="check-other" name="gender" />
              <label htmlFor="check-other">prefer not to say</label>
            </div>
          </div>
        </div>
        <div className="input-box address">
          <div className="column">
            <div className="select-box">
              <select>
                <option hidden>Country</option>
                <option>America</option>
                <option>Japan</option>
                <option>India</option>
                <option>Nepal</option>
              </select>
            </div>
            <input type="text" placeholder="Enter your city" required />
          </div>
          <div className="column">
            <input type="text" placeholder="Enter your region" required />
            <input type="number" placeholder="Enter postal code" required />
          </div>
        </div>
        <div className='tokenclass'>
          <button className='tokenbtn'>Update Profile</button>
        </div>  
        </form>
    </div>
    </div>

    </>
  )
}

export default Profile