import React, { useState } from 'react'
import '../adlogin/adlogin.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/Axios'
import { HashLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom';



const schema = yup.object({
    code: yup.string().required("Enter pin sent to your email!!").min(4, "Pin must be exactly four characters!")
  });

const Verifypin = () => {
    const setpinurl = "/admin/admin-pin-verify"
     const [loading , setloading] = useState(false)
    const navigate = useNavigate("")

    const {register, handleSubmit,formState: { errors }} = useForm({ 
        resolver: yupResolver(schema),
        code:'',
    });
    toast.error(errors.message,{
        position:toast.POSITION.TOP_CENTER
    })

    const formSubmit = async ({code})=>{
        const res = await axios.post(setpinurl)
        .then(response=>{
            //console.log(response.data);
            const {data, access_token, message} = response.data
            if(access_token){

                    localStorage.setItem('ggldata', JSON.stringify(response.data));
                    toast.success(message,{
                        position:toast.POSITION.TOP_CENTER
                    })
                    setloading()
                    setTimeout(()=>{
                        navigate("/")
                    },2000)
                }
                if(!access_token){
                    toast.error(data.data.email[0],{
                        position:toast.POSITION.TOP_CENTER
                    }) 
                }
        }).catch(error=>{
            //console.log(error);
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            }) 
        })
        return res
    }
    
  return (
    <>
       <div className='admin-container' style={{ height: "100vh" }}>
        <ToastContainer/>
            {
            loading?    
            <span>Loading...<HashLoader className='clips' color="#44045d" loading={loading} size={50}  /></span> 
            :
            <div className='admin-card'>
            <h2>Admin Login </h2>
            <div className='admin'>
                <form onSubmit={handleSubmit(formSubmit)}>
                    <div className='admincontactbox'>
                    <label htmlFor="">Password</label>
                        <input name='code' type="number" id='code' placeholder='Enter Pin' {...register('code', { required: true })} />
                        <i className='fas fa-thin fa-lock'></i>
                        <span className='errormsg'>
                            {errors.code?.message}
                        </span>
                    </div>
                
                    <div className='adminbtncls'>
                        <button type='submit' className='adminbtn btn-primary' >Send Pin</button>
                    </div>
                    <br />
                </form>
            </div>
            <span className='comname'> <small>&#169;</small> KNMP App</span>
            {/* <img src={logo} alt="gog logo" /> */}
        </div>

        }
           
        </div>
    </>
  )
}

export default Verifypin
