import React from 'react'
import './otpcheck.css'
import Input  from './Input'
import goglogo from '../../assets/logo-3.png'
import { useNavigate } from 'react-router-dom';
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from '../../api/Axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object({
    code:yup.string().min(4, "Enter the 4 digit code").required("Code field is required!")
  })

const Otpcheck = () => {

  const otpcheckurl = '/auth/email-verify'

    // const [err, setErr] = useState(false);
    const navigate = useNavigate("")
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    code:''
  });
  console.log(errors);


  const token = JSON.parse(localStorage.getItem('knmpdata'));
const headers = { 
 'Content-Type': 'application/json',
 'Authorization': `Bearer ${token.access_token}`
 }; // auth header with bearer token

 
    const codehandle= async(code)=>{
      const res = await axios.post(otpcheckurl, code, {headers})
      .then(response=>{
        //console.log(response.data);
        const {data, message} = response.data;
        if(data) {
          //localStorage.setItem('knmpdata', JSON.stringify(access_token));
          toast.success(message,{
            position:toast.POSITION.TOP_CENTER
          })
          setTimeout(()=>{
            navigate("/login")
          }, 2000)
        }
        if(data.success===false) {
          //Error(data);
          toast.error(data.message,{
            position:toast.POSITION.TOP_CENTER
          })
          return;
        }
      })
      .catch(error=>{
        toast.error(error,{
          position:toast.POSITION.TOP_CENTER
        })
        //console.log(error);
      })
      return res;
      }
  return (
    <div className='bgotp'>
      <ToastContainer/>
        <div className='container'>
            <h2>Verify your account</h2>
            <img src={goglogo} alt="gog logo" />
            <p>We sent you a four(4) digit code to your email,<br />
                Enter the code below to confirm your email address.
            </p>
            <div className='code-container'>   

            <form onSubmit={handleSubmit(codehandle)}>
            <div className='resetpwdbox'>
            <Input name='code'  type="number" id='code' otpcheck={{...register('code')}} label='Enter 4 digit Pin' 
            errormessage={errors.code?.message} i='fas fa-thin fa-right-to-bracket' />
          </div> 
            <div className='otpbtnclass'>
                <button type='submit' className='btnotp btn-primary'>Verify Code</button>
            </div>
            <small>
                If you didn't recieve a code! <strong style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Resend</strong>
            </small>
            {/* {err && <span>Something went wrong</span>} */}
            </form>
    </div>
        </div>
    </div>
  )
}

export default Otpcheck
