import React, { useState } from 'react'
import './knmgallery.css'
import img1 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.42 PM.jpeg'
import img2 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.43 PM (1).jpeg'
import img3 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.47 PM.jpeg'
import img4 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.43 PM.jpeg'
import img5 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.44 PM (2).jpeg'
import img6 from '../../assets/KN.jpeg'
import img7 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.45 PM (1).jpeg'
import img8 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.48 PM (2).jpeg'
import img9 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.45 PM (3).jpeg'
import img10 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.49 PM.jpeg'
import img11 from '../../assets/knmpimg/WhatsApp Image 2023-07-07 at 9.14.47 PM (3).jpeg'
import img12 from '../../assets/knmpimg/20230608_145951.jpg'




const Knmgallery = () => {
    const imgdata = [
        {
            id:1,
            imgsrc:img1
        }, 

        {
            id:2,
            imgsrc:img2
        },
        {
            id:3,
            imgsrc:img3
        },
        {
            id:4,
            imgsrc:img4
        },
        {
            id:5,
            imgsrc:img5
        },
        {
            id:6,
            imgsrc:img6
        },
        {
            id:7,
            imgsrc:img7
        },
        {
            id:8,
            imgsrc:img8
        },
        {
            id:9,
            imgsrc:img9
        },
        {
            id:10,
            imgsrc:img10
        },
        {
            id:11,
            imgsrc:img11
        },
        {
            id:12,
            imgsrc:img12
        }

    ]
    const [model, setmodel] = useState(false)
    const [tempingsrc, settempimgsrc] = useState('')

    const getimg=(imgsrc)=>{
        settempimgsrc(imgsrc);
        setmodel(true)
        }

  return (
<>

    <div className='knmghead'>
    <i className="fas fa-image nav-link-icon"></i>
    <p>KNMP Gallery</p>
    </div>

    <div className={model ? "model open" : "model"}> 
    <img src={tempingsrc} alt="" />
    <i className="fa-regular fa-xmark" onClick={()=>setmodel(false)}></i>
</div>

    <div className='gallery'>
        {imgdata.map((item, index)=>{
            return(
                <div className='pics' key={index} onClick={()=>getimg(item.imgsrc)}>
                    <img src={item.imgsrc} alt=""  style={{width:'100%'}}/>
                </div>
            )
        })}
    </div>
</>
  )
}

export default Knmgallery
