import React, { useState } from 'react'
import './buytickets.css'
import Buyerinfo from './Buyerinfo'
import Ticketinfo from './Ticketinfo'
// import Paymentcheckout from './Paymentcheckout'
import axios from '../../api/Axios'
import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';      
import Ticketadultinfo from './Ticketadultinfo'
import { useNavigate } from 'react-router-dom'
const Buytickets = () => {
  const navigate = useNavigate("");

const reservationurl = "/auth/user-reservation"
const [page, setpage] = useState(0)
const [formdata, setformdata] = useState({
  fullname:"",
  gender: "",
  country:"",
  city:"",
  digital_address:"",
  phone_number:"",
  reservation_date:"",
  children_visitor_category:"",
  number_of_children:"",
  adult_visitor_category:"",
  number_of_adult:""
  })
  const formtitles = ["Buyer Information", "Ticket Information", "Adult  Ticket information"]

  const pagesdisplay = () => {
    if (page === 0) {
     return <Buyerinfo formdata={formdata} setformdata={setformdata} />
    } else if (page === 1) {
        return <Ticketinfo formdata={formdata} setformdata={setformdata} />
    } else {
        return <Ticketadultinfo formdata={formdata} setformdata={setformdata} />
    }
}

const token = JSON.parse(localStorage.getItem('knmpdata'));
// console.log(token);
const headers = { 
 'Content-Type': 'application/json',
 'Authorization': `Bearer ${token.access_token}`
 }; // auth header with bearer token


 const buytickets = ()=>{
  navigate("/buytickets")
 }

 const rescheduletickets = ()=>{
  navigate("/reschedule-ticket")
 }

  return (
    <>
    <ToastContainer/>
      <div className='ticketheading'>
      <i className="fas fa-tachometer-alt nav-link-icon"></i>
      <p>Ticketing</p>
    </div>
    <button className='btnticket' onClick={buytickets}>Buy Ticket</button>
    <button className='btnticket' onClick={rescheduletickets}>Reschedule Ticket</button>

    <div className='buyticket'>
    <div className="tkcontainer">
    <div className='progressbar'>
        <div style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}></div>
    </div>
      <div className='tkheader'>{formtitles[page]}</div>
      <form className="form">
      {pagesdisplay()}
      </form>

      <div className='btntk'>
        <button type='submit' className='btnticket'
        disabled={page === 0}
        onClick={() => {
            setpage((currpage) => currpage - 1)
        }}>   
        <i className='fa fa-sharp fa-solid fa-arrow-left'></i>
        <span>Prev</span>
        </button>
        <button className='btnticket'
        onClick={() => {
          if (page === formtitles.length - 1) {
            //alert("Submited")
              //console.log(formdata);
               const res = axios.post(reservationurl, formdata, {headers})
               .then((response)=>{
                  //console.log(response);
                  const {data} = response.data;
                  console.log(data);
                  if (data){
                    toast.success(data.message,{
                      position:toast.POSITION.TOP_CENTER
                    });
                    // setTimeout(()=>{
                    //     navigate("/payment-checkout")
                    // }, 2000)
                    navigate("/ticket-status")
                    
                  }
                //   if(!data){
                //     toast.error("Something went wrong! check your details and try again",{
                //         position:toast.POSITION.TOP_CENTER,
                //         className:"danger"
                //     }) 
                // }
                 
               })
               .catch(error=>{
                  //console.log(error);
                  toast.error("Server Error!"+error)

               });
               return res
              

          } else {
              setpage((currpage) => currpage + 1)
          }
    }}  
      >
      <span>
          {page === formtitles.length - 1 ? "Submit" : "Next"}
      </span>
      <i className='fas fa-regular fa-arrow-right'></i>

        </button>
        </div>
    
    </div>
    </div>
      
    </>
  )
}

export default Buytickets
