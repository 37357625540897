import React, { useRef, useState } from 'react'
// import AuthContext from '../../context/AuthProvider';
import "./login.css"
import { useNavigate , Link} from 'react-router-dom';
import videoBg from '../../assets/vidtwo.mp4'
import goglogo from '../../assets/logo-2.png'
import Input from './Input'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from '../../api/Axios'
import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Error } from '../../utils/util';
const loginurl = '/auth/user-login'

const schema = yup.object({
  email:yup.string().required("Email field is required!").email(),
  // password:yup.string().min(8,"Password must be at least 8 Characters").required(),
  password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),

})

const Login = () => {
  // const {setAuth} = useContext(AuthContext)
  const userRef = useRef();
  const [email, setemail] = useState('');
  // const [password, setpwd] = useState('');
  const [show, setshow] = useState(false)
  const navigate = useNavigate("")
  
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    email:'',
    password:''
  }); 

   const formSubmit = async (data)=>{
       const res = await axios.post(loginurl, data)
       .then((response)=>{
        // console.log(response.data);
        const {data, access_token } = response.data;
        if(access_token){
          localStorage.setItem('knmpdata', access_token)
          localStorage.setItem('knmpdata', JSON.stringify(response.data))

          // setAuth({email, password, access_token});
          toast.success("login successfull",{
            position:toast.POSITION.TOP_CENTER
          })
          setTimeout(()=>{
            navigate("/dashboard")
          }, 3000)
        }
        if(data ){
          toast.error(data.message +" Check your credentials and try again...",{
            className:'condi',
            position:toast.POSITION.TOP_CENTER
          });
           
            toast.error(data.data.data.email[0],{
              className:'condi',
              position:toast.POSITION.TOP_CENTER
            });
         }
       })
       .catch(error=>{
        // console.log(error);
        toast.error(error.message,{
          className:'condi',
          position:toast.POSITION.TOP_CENTER
        })
       });
       return res
      }
      const handleLock = ()=>{
        setshow(!show)
      }

  return (
    <div className='loginpage'>  
      <ToastContainer />
    <div className='loginContainer'>  
    <div className="overlay"></div>
  <div>
     <video src={videoBg} autoPlay loop muted/> 
  <div className='jalabia'>
    <span className="login-logo">Kwame Nkrumah Memorial Park Portal</span>
      <img src={goglogo} alt='goglogo' />
        <span className="login-title">Login</span>
       <form className='loginform' onSubmit={handleSubmit(formSubmit)}>
          <Input refs={userRef} autoComplete="off" onChange={(e)=>setemail(e.target.value)} value={email} name='email' id='email' label='Email' type='email' i='fas fa-thin fa-envelope' 
          login={{...register('email')}} errormessage={errors.email?.message}  />

      <div className='loginbox'>
         <i className={show?"fas fa-thin fa-eye-slash":"fa fa-thin fa-eye fa-shake"} onClick={handleLock}></i>
            <input id='password' name='password' type={show?"text":"password"}  {...register('password', { required: true })}/>
            <label htmlFor='password'>{show?"Hide Password":"Show Password"}</label>
            <span className='danger'>
              {errors.password?.message}
         </span>
     </div>
        <p>Forgot Password? <Link to="/sendresetpassword-email" style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Reset</Link></p>
        <div className='btnlog'>
        <button className='btnlogin'>Login</button>
        </div>
        {/* {err && <span>Something went wrong</span>} */}
    </form>
    <p>You do have an account? <Link to="/signup" style={{color:"#000",textDecoration:"underline", fontSize:"15px", fontWeight:"400px"}}>Signup</Link></p>
</div>
  </div>

</div>
</div>



  )
}

export default Login


